import { AuthenticationConfig } from '@lbg-protection/api-client-nmp-journey'
import { Brand } from '@lbg-protection/lib-ui-monoceros'
import { AppFeatureFlags } from '../app/AppFeatureFlags'
import { AppContent } from '../app/AppContent'
import { OutageType } from '../components/OutagePage/OutageType'
import { TagInterface } from '../analytics/tags.interface'

export type JourneyNames = 'default-lloyds' | 'default-halifax' | 'halifax' | 'lloyds' | 'bos'
export interface JourneyApiConfigInterface {
  readonly protocol: string
  readonly host: string
  readonly port: number | string
  readonly mimicHost: string
  readonly mimicPort: number | string
  readonly prefix: string
  readonly mimicPrefix: string
  readonly cascadeHeaders: boolean
  readonly mimicAPIC: boolean
  readonly authConfig: AuthenticationConfig
  readonly retryConfig: RetryConfig
}

export interface RetryConfig {
  timeout?: number
  retryDelayMs?: number
  maxRetries?: number
}

export interface PlatformJourneyAvailability {
  nga: {
    ios: boolean
    android: boolean
  }
  onlineBanking: boolean
}

export interface UwMeConfig {
  enquiry: {
    salesChannel: string
    localeDesktop: string
    localeMobile: string
  }
}

export interface AnalyticsConfiguration {
  defaultTags: TagInterface
}
export type CwaEnvironmentNames = 'nga' | 'web' | 'unauth'

export interface ErrorReporterConfig {
  microServiceErrorLog: 'true' | 'false'
  networkErrorLog: 'true' | 'false'
  runtimeErrorLog: 'true' | 'false'
}

export interface EnvironmentConfigInterface {
  readonly journeyApi: JourneyApiConfigInterface
  readonly teallium: {
    readonly scriptUrl: string
  }
  readonly featureFlags: AppFeatureFlags
  readonly platformJourneyAvailability: PlatformJourneyAvailability
  readonly journeyName: JourneyNames
  readonly cwaEnvironmentName: CwaEnvironmentNames
  readonly content: AppContent
  readonly themeName: Brand
  readonly underwriteMe: UwMeConfig
  readonly showOutagePage: OutageType | false
  readonly analytics: AnalyticsConfiguration
  readonly sessionTimeoutDuration: number
  readonly sessionDialogTimeoutDuration: number
  readonly errorReporterConfig: ErrorReporterConfig
  readonly ciLed: boolean
  readonly cavendishOnlineConsentDwellTime: number
}

class Configuration {
  private config: undefined | EnvironmentConfigInterface

  public readonly load = (): Promise<void> => {
    const configUrl = `./settings/config.json?${encodeURIComponent(new Date().toISOString())}`
    return fetch(configUrl)
      .then<EnvironmentConfigInterface>((response) => response.json())
      .then((config) => {
        this.config = config
      })
  }

  public readonly get = async (): Promise<EnvironmentConfigInterface> => {
    if (!this.config) {
      await this.load()
    }
    return JSON.parse(JSON.stringify(this.config)) as EnvironmentConfigInterface
  }
}

export const baseUrlPath = (): string => {
  let path = ''
  if (window.location.href.match('scottishwidows')) return `/life-insurance`
  if (window.location.href.match('sales-content')) {
    const knownBrandPathVar = /(\/b\/|\/h\/|\/l\/)/
    const [pathVariable] = window.location.href.match(knownBrandPathVar) ?? ['']
    path = `/sales-content/cwa${pathVariable}life-insurance`
  } else {
    const knownPathVariables = /(icp|d2c|ocp)/
    const [pathVariable] = window.location.href.match(knownPathVariables) ?? ['']
    path = `/protection-${pathVariable}/cwa/life-insurance`
  }
  return window.location.href.search(new RegExp(path, 'i')) > 0 ? path : ''
}

export const config = new Configuration()
