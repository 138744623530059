import { v4 as uuidGen } from 'uuid'
import {
  AboutYouResponseData,
  AnswerUnderwritingQuestionRequestBody,
  AnswerUnderwritingQuestionRequestPathParams,
  CloseUnderwritingEnquiryRequestPathParams,
  CreatePolicyApplicationRequestBodyV2 as CreatePolicyApplicationRequestBody,
  EmailSavedQuoteRequestBody,
  OptionListResponseBodyData,
  PersistenceRetrieveResponseBodyData,
  PersistenceSaveRequestBody,
  PricingRequestBody,
  PricingRequestBodyV2,
  PricingResponseBodyData,
  RetrieveUnderwritingQuestionOptionListRequestPathParams,
  RetrieveUnderwritingQuestionOptionListRequestQuery,
  ServiceClient as JourneyClient,
  StartUnderwritingEnquiryRequestBody,
  StartUnderwritingEnquiryRequestQuery,
  UnderwritingEnquiryResponseBodyData,
  UserInfo,
} from '@lbg-protection/api-client-nmp-journey'
import { config, JourneyApiConfigInterface, JourneyNames } from '../config'
import { onErrorThirdPartyLogger } from '../errorReporter/ErrorReporter/ErrorReporter'
import { getDefaultOutletHeadersByBrand } from './utils'

interface UserDetails {
  extptyId: string
  extsysId: string
  ocisId: string
  userId: string
}

let apiClient: JourneyClient & { isMimickingApiConnect?: boolean }

let userDetails: UserDetails

const getJourneyServiceConfig = async (): Promise<JourneyApiConfigInterface> => {
  const response = await config.get()
  return response.journeyApi
}

const lazyInstantiate = async (): Promise<void> => {
  if (!apiClient) {
    const journeyServiceConfig = await getJourneyServiceConfig()
    const isMimicAPIC = `${journeyServiceConfig?.mimicAPIC}` === `true`
    let host: string
    let port: number
    let prefix: string
    if (isMimicAPIC) {
      host = `${journeyServiceConfig.protocol}://${journeyServiceConfig.mimicHost}`
      port = Number(journeyServiceConfig.mimicPort)
      prefix = journeyServiceConfig.mimicPrefix
    } else {
      host = `${journeyServiceConfig.protocol}://${journeyServiceConfig.host}`
      port = Number(journeyServiceConfig.port)
      prefix = journeyServiceConfig.prefix
    }

    apiClient = new JourneyClient({
      cascadeHeaders: Boolean(journeyServiceConfig.cascadeHeaders),
      host,
      port,
      prefix,
      maxRetries: journeyServiceConfig.retryConfig.maxRetries,
      retryDelayMs: journeyServiceConfig.retryConfig.retryDelayMs,
      authConfig: journeyServiceConfig.authConfig,
      ignoreResponseValidation: true,
    })
    apiClient.isMimickingApiConnect = isMimicAPIC
  }
}

const getDefaultHeaders = (): Record<string, string> => {
  const headers: Record<string, string> = {
    'X-Correlation-ID': uuidGen(),
    'x-lbg-user-id': userDetails.userId,
  }
  if (apiClient.isMimickingApiConnect) {
    // This enables teting without API connect being in the middle
    headers['x-lbg-txn-customer-id'] = `${userDetails.ocisId},${userDetails.extsysId},${userDetails.extptyId}`
  }
  return headers
}

const createPolicyApplication = async (data: CreatePolicyApplicationRequestBody): Promise<void> => {
  try {
    await lazyInstantiate()

    await apiClient.createPolicyApplicationV2({
      body: data,
      headers: getDefaultHeaders(),
    })
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const getAboutYou = async (brand: JourneyNames): Promise<AboutYouResponseData> => {
  try {
    await lazyInstantiate()

    const outletHeaders = getDefaultOutletHeadersByBrand(brand)
    const xOutletHeaders = {
      'x-lbg-outlet-id': outletHeaders?.outletId.toString() ?? '',
      'x-lbg-outlet-id-type': outletHeaders?.outletIdType ?? '',
      'x-lbg-brand': outletHeaders?.brand ?? '',
    }

    const aboutYouResponse = await apiClient.getAboutYou({}, { headers: { ...getDefaultHeaders(), ...xOutletHeaders } })
    return aboutYouResponse.data
  } catch (err) {
    onErrorThirdPartyLogger(err)
    throw err
  }
}

const getPricingAnonymousQuote = async (body: PricingRequestBody): Promise<PricingResponseBodyData> => {
  try {
    await lazyInstantiate()

    const pricingQuote = await apiClient.getPricingAnonymousQuote({ body, headers: getDefaultHeaders() })

    return pricingQuote.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const getPricingAnonymousQuoteV2 = async (body: PricingRequestBodyV2): Promise<PricingResponseBodyData> => {
  try {
    await lazyInstantiate()

    const pricingQuote = await apiClient.getPricingAnonymousQuoteV2({ body, headers: getDefaultHeaders() })

    return pricingQuote.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const getUserInfo = async (): Promise<UserInfo> => {
  try {
    await lazyInstantiate()
    const userInfo: UserInfo = await apiClient.getUserInfo()
    const { user } = userInfo
    userDetails = {
      extptyId: user.externalPartyId,
      extsysId: user.externalSystemId,
      ocisId: user.ocisId,
      userId: user.id,
    }
    return userInfo
  } catch (error) {
    if (process.env.REACT_APP_DEVELOPMENT_JOURNEY_ENABLED === 'true') {
      const { getUserInfoFromURL } = await import(
        /* webpackChunkName: "loadMockUserInfo" */ '../developmentSetMockUserInfo'
      )
      const userInfo = getUserInfoFromURL()
      const { user } = userInfo
      userDetails = {
        extptyId: user.externalPartyId,
        extsysId: user.externalSystemId,
        ocisId: user.ocisId,
        userId: user.id,
      }
      return userInfo
    }
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const uwMeStartEnquiry = async (
  body: StartUnderwritingEnquiryRequestBody,
  query: StartUnderwritingEnquiryRequestQuery,
): Promise<UnderwritingEnquiryResponseBodyData> => {
  try {
    await lazyInstantiate()

    const startEnquiryResponse = await apiClient.startUnderwritingEnquiry({
      body,
      query,
      headers: getDefaultHeaders(),
    })

    return startEnquiryResponse.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const uwMeAnswerEnquiryQuestion = async (
  body: AnswerUnderwritingQuestionRequestBody,
  path: AnswerUnderwritingQuestionRequestPathParams,
): Promise<UnderwritingEnquiryResponseBodyData> => {
  try {
    await lazyInstantiate()

    const answerQuestionResponse = await apiClient.answerUnderwritingQuestion({
      body,
      path,
      headers: getDefaultHeaders(),
    })

    return answerQuestionResponse.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const uwMeCloseEnquiry = async (
  path: CloseUnderwritingEnquiryRequestPathParams,
): Promise<UnderwritingEnquiryResponseBodyData> => {
  try {
    await lazyInstantiate()

    const closeEnquiryResponse = await apiClient.closeUnderwritingEnquiry({
      path,
      headers: getDefaultHeaders(),
    })

    return closeEnquiryResponse.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const getOptionBackedQuestionOptions = async (
  path: RetrieveUnderwritingQuestionOptionListRequestPathParams,
  query: RetrieveUnderwritingQuestionOptionListRequestQuery,
): Promise<OptionListResponseBodyData> => {
  try {
    await lazyInstantiate()

    const resp = await apiClient.retrieveUnderwritingQuestionOptionList({ path, query, headers: getDefaultHeaders() })
    return resp.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const saveJourney = async (body: PersistenceSaveRequestBody): Promise<void> => {
  try {
    await lazyInstantiate()

    await apiClient.persistenceSave({ body, headers: getDefaultHeaders() })
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const processSavedQuoteEmail = async (body: EmailSavedQuoteRequestBody): Promise<void> => {
  try {
    await lazyInstantiate()

    await apiClient.emailSaveQuote({
      body,
      headers: getDefaultHeaders(),
    })
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const retrieveJourney = async (): Promise<PersistenceRetrieveResponseBodyData> => {
  try {
    await lazyInstantiate()

    const response = await apiClient.persistenceRetrieve({ headers: getDefaultHeaders() })

    return response.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

export const authJourneyApi = () => {
  return {
    createPolicyApplication,
    getAboutYou,
    getPricingAnonymousQuote,
    getPricingAnonymousQuoteV2,
    getUserInfo,
    processSavedQuoteEmail,
    retrieveJourney,
    saveJourney,
    uwMeAnswerEnquiryQuestion,
    uwMeCloseEnquiry,
    uwMeStartEnquiry,
    getOptionBackedQuestionOptions,
  }
}
